<template>
  <div class="view-home">
    <div class="container">
      <div class="quiz-status my-4">
        <div class="card" v-show="countdown">
          <ul>
            <li class="time">
              {{ countdown }}
            </li>
            <li class="recording" v-if="quiz.proctoring">
              {{ $t('testing.recording') }}
            </li>
            <li class="interruption" v-if="quiz.proctoring">
              {{ $t('testing.interruption') }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container questions-wrap">
      <h2>
        {{ $t('funny-stories-15-17.title') }}
      </h2>
      <h3>
        {{ $t('funny-stories-15-17.subtitle') }}
      </h3>
      <div class="collaboration-desc">
        {{ $t('funny-stories-15-17.instruction') }}
      </div>

      <div class="d-flex flex-column">

        <div class="question-radio">
          1. <img src="/images/subtest2-funny-stories/Batyr2.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.question-1') }}
          <div class="question-radio-list">
            <el-radio v-model="answersFunnyStories[0].choice" label="А">
              А) <img src="/images/subtest2-funny-stories/Batyr2-1.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-1-1') }}
            </el-radio>
            <el-radio v-model="answersFunnyStories[0].choice" label="Б">
              Б) <img src="/images/subtest2-funny-stories/Batyr2-2.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-1-2') }}
            </el-radio>
            <el-radio v-model="answersFunnyStories[0].choice" label="В">
              В) <img src="/images/subtest2-funny-stories/Batyr2-3.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-1-3') }}
            </el-radio>
          </div>
        </div>
        <div class="question-radio">
          2. <img src="/images/subtest2-funny-stories/Batyr5.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.question-2') }}
          <div class="question-radio-list">
            <el-radio v-model="answersFunnyStories[1].choice" label="А">
              А) <img src="/images/subtest2-funny-stories/Batyr5-1.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-2-1') }}
            </el-radio>
            <el-radio v-model="answersFunnyStories[1].choice" label="Б">
              Б) <img src="/images/subtest2-funny-stories/Batyr5-2.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-2-2') }}
            </el-radio>
            <el-radio v-model="answersFunnyStories[1].choice" label="В">
              В) <img src="/images/subtest2-funny-stories/Batyr5-3.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-2-3') }}
            </el-radio>
          </div>
        </div>
        <div class="question-radio">
          3. <img src="/images/subtest2-funny-stories/Batyr4.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.question-3') }}
          <div class="question-radio-list">
            <el-radio v-model="answersFunnyStories[2].choice" label="А">
              А) <img src="/images/subtest2-funny-stories/Batyr4-1.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-3-1') }}
            </el-radio>
            <el-radio v-model="answersFunnyStories[2].choice" label="Б">
              Б) <img src="/images/subtest2-funny-stories/Batyr4-2.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-3-2') }}
            </el-radio>
            <el-radio v-model="answersFunnyStories[2].choice" label="В">
              В) <img src="/images/subtest2-funny-stories/Batyr4-3.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-3-3') }}
            </el-radio>
          </div>
        </div>
        <div class="question-radio">
          4. <img src="/images/subtest2-funny-stories/Batyr6.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.question-4') }}
          <div class="question-radio-list">
            <el-radio v-model="answersFunnyStories[3].choice" label="А">
              А) <img src="/images/subtest2-funny-stories/Batyr6-1.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-4-1') }}
            </el-radio>
            <el-radio v-model="answersFunnyStories[3].choice" label="Б">
              Б) <img src="/images/subtest2-funny-stories/Batyr6-2.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-4-2') }}
            </el-radio>
            <el-radio v-model="answersFunnyStories[3].choice" label="В">
              В) <img src="/images/subtest2-funny-stories/Batyr6-3.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-4-3') }}
            </el-radio>
          </div>
        </div>
        <div class="question-radio">
          5. <img src="/images/subtest2-funny-stories/Batyr1.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.question-5') }}
          <div class="question-radio-list">
            <el-radio v-model="answersFunnyStories[4].choice" label="А">
              А) <img src="/images/subtest2-funny-stories/Batyr1-1.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-5-1') }}
            </el-radio>
            <el-radio v-model="answersFunnyStories[4].choice" label="Б">
              Б) <img src="/images/subtest2-funny-stories/Batyr1-2.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-5-2') }}
            </el-radio>
            <el-radio v-model="answersFunnyStories[4].choice" label="В">
              В) <img src="/images/subtest2-funny-stories/Batyr1-3.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-5-3') }}
            </el-radio>
          </div>
        </div>
        <div class="question-radio">
          6. <img src="/images/subtest2-funny-stories/Batyr3.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.question-6') }}
          <div class="question-radio-list">
            <el-radio v-model="answersFunnyStories[5].choice" label="А">
              А) <img src="/images/subtest2-funny-stories/Batyr3-1.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-6-1') }}
            </el-radio>
            <el-radio v-model="answersFunnyStories[5].choice" label="Б">
              Б) <img src="/images/subtest2-funny-stories/Batyr3-2.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-6-2') }}
            </el-radio>
            <el-radio v-model="answersFunnyStories[5].choice" label="В">
              В) <img src="/images/subtest2-funny-stories/Batyr3-3.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-6-3') }}
            </el-radio>
          </div>
        </div>
        <div class="question-radio">
          7. <img src="/images/subtest2-funny-stories/Batyr10.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.question-7') }}
          <div class="question-radio-list">
            <el-radio v-model="answersFunnyStories[6].choice" label="А">
              А) <img src="/images/subtest2-funny-stories/Batyr10-1.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-7-1') }}
            </el-radio>
            <el-radio v-model="answersFunnyStories[6].choice" label="Б">
              Б) <img src="/images/subtest2-funny-stories/Batyr10-2.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-7-2') }}
            </el-radio>
            <el-radio v-model="answersFunnyStories[6].choice" label="В">
              В) <img src="/images/subtest2-funny-stories/Batyr10-3.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-7-3') }}
            </el-radio>
          </div>
        </div>
        <div class="question-radio">
          8. <img src="/images/subtest2-funny-stories/Batyr9.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.question-8') }}
          <div class="question-radio-list">
            <el-radio v-model="answersFunnyStories[7].choice" label="А">
              А) <img src="/images/subtest2-funny-stories/Batyr9-1.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-8-1') }}
            </el-radio>
            <el-radio v-model="answersFunnyStories[7].choice" label="Б">
              Б) <img src="/images/subtest2-funny-stories/Batyr9-2.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-8-2') }}
            </el-radio>
            <el-radio v-model="answersFunnyStories[7].choice" label="В">
              В) <img src="/images/subtest2-funny-stories/Batyr9-3.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-8-3') }}
            </el-radio>
          </div>
        </div>
        <div class="question-radio">
          9. <img src="/images/subtest2-funny-stories/Batyr8.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.question-9') }}
          <div class="question-radio-list">
            <el-radio v-model="answersFunnyStories[8].choice" label="А">
              А) <img src="/images/subtest2-funny-stories/Batyr8-1.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-9-1') }}
            </el-radio>
            <el-radio v-model="answersFunnyStories[8].choice" label="Б">
              Б) <img src="/images/subtest2-funny-stories/Batyr8-2.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-9-2') }}
            </el-radio>
            <el-radio v-model="answersFunnyStories[8].choice" label="В">
              В) <img src="/images/subtest2-funny-stories/Batyr8-3.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-9-3') }}
            </el-radio>
          </div>
        </div>
        <div class="question-radio">
          10. <img src="/images/subtest2-funny-stories/Batyr7.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.question-10') }}
          <div class="question-radio-list">
            <el-radio v-model="answersFunnyStories[9].choice" label="А">
              А) <img src="/images/subtest2-funny-stories/Batyr7-1.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-10-1') }}
            </el-radio>
            <el-radio v-model="answersFunnyStories[9].choice" label="Б">
              Б) <img src="/images/subtest2-funny-stories/Batyr7-2.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-10-2') }}
            </el-radio>
            <el-radio v-model="answersFunnyStories[9].choice" label="В">
              В) <img src="/images/subtest2-funny-stories/Batyr7-3.png" width="100px" height="100px" alt=""/> {{ $t('funny-stories-15-17.answer-10-3') }}
            </el-radio>
          </div>
        </div>
      </div>

      <!--      <button @click="sendCollaborationResults">Отправить</button>-->
      <el-button @click="openModal"
                 class="btn btn-danger">{{ $t('testing.finish-modal-button') }}
      </el-button>

    </div>
    <b-modal id="exit-modal" hide-footer hide-header>
      <div id="modal-wrapper">
        <div id="dialog">
          <button @click="$bvModal.hide('exit-modal')" type="button" class="close" data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <img class="modal-warning-img mb-3" src="/images/profile/modal-warning.svg">
          <h3 class="mb-0">{{modalTitle}}</h3>
          <p>{{ $t('testing.finish-modal-desc') }}</p>
          <div class="clearfix pt-3">
            <el-button @click="sendIncorrectPhraseResults(false)" class="btn btn-primary mx-2">
              {{ $t('testing.finish-modal-button') }}
            </el-button>
            <a @click="$bvModal.hide('exit-modal')"
               class="btn btn-outline-primary mx-2">{{ $t('testing.finish-modal-cancel') }}</a>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import 'katex/dist/katex.min.css';
import Loader from '../../components/Loader';
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue';

Vue.use(VueViewer)
export default {
  name: "Index",
  data() {
    return {
      modalTitle: this.$t('testing.finish-modal-title'),
      timerInterval: null,
      countdown: 0,
      quiz: '',
      answersFunnyStories: [
        { choice: "", question: "1" },
        { choice: "", question: "2" },
        { choice: "", question: "3" },
        { choice: "", question: "4" },
        { choice: "", question: "5" },
        { choice: "", question: "6" },
        { choice: "", question: "7" },
        { choice: "", question: "8" },
        { choice: "", question: "9" },
        { choice: "", question: "10" }
      ],
      score: null,
      correctAnswers: ["В", "В", "В", "Б", "Б", "В", "В", "А", "В", "В"],
      interpretationResult: {
        high: {
          ru : "Высокий уровень коммуникативных способностей и коллаборации.<br>У тебя выраженные коммуникативные навыки, что позволяет легко и быстро устанавливать контакт с окружающими. Ты обладаешь природным умением взаимодействовать и находить общий язык с разными людьми. Это качество помогает тебе эффективно работать в команде и достигать общих целей.<br>Твои способности в области коммуникации включают умение ясно выражать свои мысли, активно слушать других, а также эффективно обмениваться информацией. Ты также способен справляться с конфликтами и находить компромиссы, что делает тебя ценным членом любой группы.<br>Чтобы поддерживать и развивать свои навыки, рекомендуется активно участвовать в общественных мероприятиях, как в классе, так и в школе. Присоединяйся к клубам, принимай участие в школьных проектах и мероприятиях. Это не только укрепит твои коммуникативные навыки, но и поможет тебе расширить кругозор и познакомиться с новыми людьми.",
          kz : "Коммуникативті қабілеттер мен коллабарацияның жоғары деңгейі.<br>Сізде коммуникативтік дағдылары бар, бұл басқалармен оңай және жылдам байланыс орнатуға мүмкіндік береді. Сізде әртүрлі адамдармен қарым-қатынас жасау және ортақ тіл табу табиғи қабілеті бар. Бұл сапа сізге командада тиімді жұмыс істеуге және ортақ мақсаттарға жетуге көмектеседі.<br>Сіздің коммуникативтік қабілеттеріңізге өз ойларыңызды нақты жеткізе білу, басқаларды белсенді тыңдау, сондай-ақ ақпаратпен тиімді бөлісу мүмкіндігі кіреді. Сіз сондай-ақ қақтығыстармен күресуге және ымыраға келуге қабілеттісіз, бұл сізді кез келген топтың құнды мүшесі етеді.<br>Өз дағдыларыңызды сақтау және дамыту үшін сыныпта да, мектепте де қоғамдық іс-шараларға белсенді қатысқан жөн. Клубтарға қосылыңыз, мектеп жобалары мен іс-шараларына қатысыңыз. Бұл сіздің қарым-қатынас дағдыларыңызды нығайтып қана қоймай, көкжиегіңізді кеңейтуге және жаңа адамдармен танысуға көмектеседі.",
        },
        middle: {
          ru : "Средний уровень коммуникативных способностей и коллаборации.<br>Ты демонстрируешь хорошие коммуникативные способности, однако есть потенциал для роста. В данный момент ты способен эффективно общаться и работать в команде, но можешь столкнуться с некоторыми трудностями при установлении контактов и преодолении коммуникативных барьеров.<br>Для дальнейшего развития рекомендуем активнее работать над получением обратной связи и преодолением возможных преград в общении. Важно научиться лучше понимать сигналы и реакции окружающих, а также преодолевать личные трудности в коммуникации.<br>Психологические занятия могут помочь тебе улучшить уверенность в себе, развить навыки активного слушания и научиться более эффективно справляться с трудностями в общении. Эти занятия помогут укрепить твои коммуникативные умения и сделать их более гибкими и адаптивными.",
          kz : "Коммуникативті қабілеттер мен коллабарацияның орташа деңгейі.<br>Сіз жақсы коммуникативтік қабілеттерін көрсетесіз, бірақ өсу мүмкіндігі бар. Қазіргі уақытта сіз тиімді қарым-қатынас жасай аласыз және командада жұмыс істей аласыз, бірақ сіз байланыс орнатуда және коммуникативті кедергілерді жеңуде кейбір қиындықтарға тап болуыңыз мүмкін.<br>Әрі қарай даму үшін кері байланыс алу және қарым-қатынастағы мүмкін кедергілерді жеңу үшін белсенді жұмыс істеуді ұсынамыз. Басқалардың сигналдары мен реакцияларын жақсы түсінуді үйрену, сондай-ақ қарым-қатынастағы жеке қиындықтарды жеңу маңызды.<br>Сізге өзіңізге деген сенімділікті арттыруға, белсенді тыңдау дағдыларын дамытуға және қарым-қатынас қиындықтарымен тиімді күресуді үйренуге психологиялық сабақтар көмектеседі. Бұл сабақтар сіздің қарым-қатынас дағдыларыңызды нығайтуға және оларды икемді және бейімделгіш етуге көмектеседі.",
        },
        low: {
          ru : "Коммуникативные способности «в зоне ближайшего развития».<br>Твои коммуникативные навыки в данный момент требуют улучшения. Возможно, ты испытываешь трудности в общении с другими людьми и не всегда уверен в себе. Это может создавать преграды при установлении контактов и взаимодействии с окружающими.<br>Чтобы улучшить свои коммуникативные способности, рекомендуется сосредоточиться на развитии уверенности в себе и навыков общения. Это можно сделать через участие в психологических занятиях, которые помогут развить уверенность, обучиться эффективным методам коммуникации и преодолеть внутренние барьеры.<br>Работа над собой в этом направлении позволит тебе лучше понимать других людей, более свободно выражать свои мысли и чувства, а также улучшить взаимодействие в различных социальных ситуациях.",
          kz : "Коммуникативті қабілеттер «жақын даму аймағы»<br>Қазіргі уақытта сіздің коммуникативті қарым-қатынас дағдыларыңыз жетілдіруді қажет етеді. Сіз басқа адамдармен қарым-қатынас жасауда қиындықтарға тап болуыңыз мүмкін және әрқашан өзіңізге сенімді бола бермеуіңіз мүмкін. Бұл басқалармен байланыс орнату және өзара әрекеттесу кезінде кедергілер тудыруы мүмкін.<br>Коммуникативті қабілетін жақсарту үшін өзіне деген сенімділік пен қарым-қатынас дағдыларын дамытуға назар аударған жөн. Мұны сенімділікті дамытуға, қарым-қатынастың тиімді әдістерін үйренуге және ішкі кедергілерді жеңуге көмектесетін психологиялық сабақтарға қатысу арқылы жасауға болады.<br>Осы бағытта өзіңізбен жұмыс істеу сізге басқа адамдарды жақсы түсінуге, өз ойларыңыз бен сезімдеріңізді еркін білдіруге, сондай-ақ әртүрлі әлеуметтік жағдайларда өзара әрекеттесуді жақсартуға мүмкіндік береді.",
        },
      }
      // interpretationResult: {
      //   high: this.$t('funny-stories-15-17.interpretation-high'),
      //   middle: this.$t('funny-stories-15-17.interpretation-middle'),
      //   low: this.$t('funny-stories-15-17.interpretation-low'),
      // }
    }
  },
  methods: {
    interpretScore(score) {
      if (score >= 18) {
        return this.interpretationResult.high;
      } else if (score >= 10) {
        return this.interpretationResult.middle;
      } else {
        return this.interpretationResult.low;
      }
    },
    validateAnswers() {
      return this.answersFunnyStories.some(
          (answer) => answer.choice.trim() === ""
      );
    },
    openModal() {
      this.$bvModal.show('exit-modal');
    },
    async sendIncorrectPhraseResults(timer) {

      if (!timer && this.validateAnswers()) {

        this.$bvModal.hide('exit-modal');

        return Vue.toastr({
          message: this.$t('error-title'),
          description: this.$t('answerAllQuestions'),
          type: 'error'
        })
      }

      this.score = this.correctAnswers.reduce((total, correct, index) => {
        return total + (this.answersFunnyStories[index].choice === correct ? 1 : 0);
      }, 0);

      let params = JSON.parse(localStorage.getItem('quiz-params-15-17'))
      const incorrectPhraseScore = params.answer_id.incorrectPhraseData.score
      const totalScore = incorrectPhraseScore + this.score

      const funnyStoriesData = {
        score: this.score,
        answers: this.answersFunnyStories,
        interpret: this.interpretScore(totalScore),
        addInfo : {
          totalScore,
        }
      }

      params.answer_id = {...params.answer_id, funnyStoriesData}

      localStorage.setItem('quiz-params-15-17', JSON.stringify(params))
      const quizToken = localStorage.getItem('quiz-token')

      const response = await this.$http.post(API_ROOT + '/api/user-answer/' + quizToken, params)

      if (response.body.status == 'success') {

        localStorage.setItem('current-test', 'thomas-questionnaire')

        Vue.toastr({
          message: this.$t('successful'),
          description: this.$t('saved-successfully'),
          type: 'success'
        })
      } else {
        Vue.toastr({
          message: this.$t('error-title'),
          description: this.$t('error-saving'),
          type: 'error'
        })
      }
      console.log('params', params)

      setTimeout(() => {
        this.$router.push({ path: '/about-new/collaboration-2' })
      }, 1500)

    },
    startTimer(duration) {
      let timer = duration, minutes, seconds;
      this.timerInterval = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.countdown = minutes + ":" + seconds;

        let savedTimer = (parseInt(minutes) * 60) + parseInt(seconds);
        localStorage.setItem('timerFunnyStories', savedTimer);

        if (--timer < 0) {
          this.sendIncorrectPhraseResults(true)
          clearInterval(this.timerInterval);
        }
      }, 1000);
    },
    redirectIfEnded() {
      const quizToken = localStorage.getItem('quiz-token');
      const userQuizId = localStorage.getItem('user_quiz_id');
      const quizEnded = localStorage.getItem('quiz-ended');

      if (quizEnded && quizEnded === `${userQuizId}-${quizToken}`) {
        window.location.href = `${window.QABILET_ROOT}/test-results-15-17/${userQuizId}/${quizToken}`
      }
    },
  },
  mounted() {
    this.redirectIfEnded();

    if (localStorage.getItem('timerFunnyStories')) {
      this.startTimer(localStorage.getItem('timerFunnyStories'))
    } else {
      this.startTimer(300)
    }

    if (localStorage.getItem('current-test')) {
      this.$router.push({name: localStorage.getItem('current-test')});
    }

    const testLang = localStorage.getItem('test_lang');
    if (testLang) this.$i18n.locale = testLang;
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  }
}
</script>

<style>
.select-custom-collaboration {
  width: 240px !important;
}

.collaboration-desc {
  margin: 20px 0;
}

.collaboration-options {
  cursor: pointer;
  position: relative;
}

.el-select-group__title {
  position: relative;
}

.el-select-group__title::after {
  position: absolute;
  right: 10px;
  top: 2px;
  content: '▼';
}


</style>
